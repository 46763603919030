import React, { useContext, useEffect, useState } from "react"
// import {palette} from "../../../utils/style-utils";
import { StandardPoolPageContainer } from "../components/PoolPage"
import BracketPoolHome from "./MultipleEntriesArea/PoolHome/BracketPoolHome"
import { useHistory, useLocation } from "react-router-dom"
import JoinedToPoolModal from "../components/EntryModals/JoinedToPoolModal"
import Modal from "@cbs-sports/sports-shared-client/build/cjs/components/Modal"
import { TPoolRouteProps } from "../../../../routes.d"
import { IPickUtilsGameSettingsValues } from "../../../../common/common-utils-types"
import ClientSettingsContext, { ClientSettingsContextType } from "../../../Contexts/ClientSettingsContext"

interface IStandingsProps extends TPoolRouteProps, IPickUtilsGameSettingsValues {
  analyticsTitle?: string
  header?: React.ReactNode
}

const PoolStandings: React.FC<IStandingsProps> = (props) => {
  const location = useLocation()
  const history = useHistory()
  const [showModal, setShowModal] = useState<null | string>(null)
  const modalId: string = (history.location.state && history.location.state["modal"] && history.location.state["modal"]["modalId"]) || ""

  const hideModal = () => {
    const { state } = location ?? {}
    if (state && state["modal"]) {
      const newState: any = { ...state }
      delete newState.modal
      history.replace({ ...location, state: newState })
    } else {
      history.replace(location)
    }
    setShowModal(null)
  }

  useEffect(() => {
    if (modalId) {
      if (!showModal && modalId === JoinedToPoolModal.modalKey) {
        setShowModal(modalId)
      }
    }
  }, [modalId, showModal])

  const clientSettingsContext: ClientSettingsContextType = useContext(ClientSettingsContext)

  const joinedPoolModal = (
    <Modal
      modalType="modal"
      padded={false}
      isOpen={!!showModal}
      variant={"white"}
      onBackgroundClick={() => undefined}
      onEscapeKeydown={() => undefined}
    >
      <JoinedToPoolModal onClose={hideModal} />
    </Modal>
  )

  return (
    <StandardPoolPageContainer {...props} showNeuronPromo={clientSettingsContext?.SHOW_POOL_HOME_NEURON_PROMO === "true"}>
      <BracketPoolHome {...props} />
      {joinedPoolModal}
    </StandardPoolPageContainer>
  )
}

export default PoolStandings
